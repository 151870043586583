// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-bfs-bfs__node_discovered--1Rlyp{background-color:#f9c74f}.components-bfs-bfs__node_visited--3b8q7{background-color:#f8961e}.components-bfs-bfs__node_active--2bvg0{background-color:#90be6d}.components-bfs-bfs__colorKey--2BkuG{margin-bottom:5px}.components-bfs-bfs__graph--zzJ1N{display:flex;flex-direction:column;align-items:center}.components-bfs-bfs__container--2ALkK{margin-bottom:5px}\n", ""]);
// Exports
exports.locals = {
	"node_discovered": "components-bfs-bfs__node_discovered--1Rlyp",
	"node_visited": "components-bfs-bfs__node_visited--3b8q7",
	"node_active": "components-bfs-bfs__node_active--2bvg0",
	"colorKey": "components-bfs-bfs__colorKey--2BkuG",
	"graph": "components-bfs-bfs__graph--zzJ1N",
	"container": "components-bfs-bfs__container--2ALkK"
};
module.exports = exports;
