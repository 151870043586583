// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-page-index__page--2JPTK{padding:0 20px}\n", ""]);
// Exports
exports.locals = {
	"page": "components-page-index__page--2JPTK"
};
module.exports = exports;
