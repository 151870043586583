// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-colorKey-colorKey__container--32RTQ{display:flex}.components-colorKey-colorKey__color--2NWuC{display:flex;margin-right:20px}.components-colorKey-colorKey__circle--tGkwi{height:25px;width:25px;border-radius:50%;margin-right:5px}\n", ""]);
// Exports
exports.locals = {
	"container": "components-colorKey-colorKey__container--32RTQ",
	"color": "components-colorKey-colorKey__color--2NWuC",
	"circle": "components-colorKey-colorKey__circle--tGkwi"
};
module.exports = exports;
