// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-edge-edge__line--11xpy{position:absolute;top:0;left:0;height:100%;width:100%;pointer-events:none}.components-edge-edge__svg--2HF62{overflow:visible !important}.components-edge-edge__svgPath--2C1GD{stroke:rgba(0,0,0,0.5);stroke-width:1;fill:none;transition:all 50ms ease-in}\n", ""]);
// Exports
exports.locals = {
	"line": "components-edge-edge__line--11xpy",
	"svg": "components-edge-edge__svg--2HF62",
	"svgPath": "components-edge-edge__svgPath--2C1GD"
};
module.exports = exports;
