// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-node-node__node--37e44{align-items:center;background-color:white;border-radius:50%;border:2px solid #577590;box-shadow:0 0 3px 1px #d5d9d8;display:flex;justify-content:center;z-index:10;height:50px;width:50px}\n", ""]);
// Exports
exports.locals = {
	"node": "components-node-node__node--37e44"
};
module.exports = exports;
