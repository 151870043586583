// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pages-home-home__section--2CG-V{max-width:680px;margin:0 auto 50px auto}\n", ""]);
// Exports
exports.locals = {
	"section": "pages-home-home__section--2CG-V"
};
module.exports = exports;
