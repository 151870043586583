// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-node-graphNode__node--3XWMc{cursor:pointer;transition:top 50ms ease-out, left 50ms ease-out, box-shadow 100ms}.components-node-graphNode__node--3XWMc:hover{box-shadow:0 0 8px 3px #d5d9d8}\n", ""]);
// Exports
exports.locals = {
	"node": "components-node-graphNode__node--3XWMc"
};
module.exports = exports;
