// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-graph-graph__graph--Lr2BA{background-color:#fbf9f6;border-radius:2px;border:1px solid gray;position:relative;overflow:hidden}.components-graph-graph__node--3W9s7{position:absolute}\n", ""]);
// Exports
exports.locals = {
	"graph": "components-graph-graph__graph--Lr2BA",
	"node": "components-graph-graph__node--3W9s7"
};
module.exports = exports;
