// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-playControls-playControls__container--2Cjbl{width:100%;text-align:center}.components-playControls-playControls__progressBar--e-e3q{background:linear-gradient(#43aa8b, #43aa8b) no-repeat;transition:background-size 100ms;border:1px solid #d5d9d8}.components-playControls-playControls__playPause--jrcfK{width:50px}.components-playControls-playControls__controls--rkdj6>button{margin:0 3px}\n", ""]);
// Exports
exports.locals = {
	"container": "components-playControls-playControls__container--2Cjbl",
	"progressBar": "components-playControls-playControls__progressBar--e-e3q",
	"playPause": "components-playControls-playControls__playPause--jrcfK",
	"controls": "components-playControls-playControls__controls--rkdj6"
};
module.exports = exports;
