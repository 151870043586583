// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-coloring-coloring__node_red--1vm-3{background-color:#f94144}.components-coloring-coloring__node_blue--1ybEb{background-color:#577590}.components-coloring-coloring__graph--1eVf5{display:flex;flex-direction:column;align-items:center}.components-coloring-coloring__container--_wKVH{margin-bottom:5px}\n", ""]);
// Exports
exports.locals = {
	"node_red": "components-coloring-coloring__node_red--1vm-3",
	"node_blue": "components-coloring-coloring__node_blue--1ybEb",
	"graph": "components-coloring-coloring__graph--1eVf5",
	"container": "components-coloring-coloring__container--_wKVH"
};
module.exports = exports;
