// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".components-dfs-dfs__node_discovered--3Gxcd{background-color:#f9c74f}.components-dfs-dfs__node_visited--JFfyg{background-color:#f8961e}.components-dfs-dfs__node_active--1NKWV{background-color:#90be6d}.components-dfs-dfs__colorKey--2ajJs{margin-bottom:5px}.components-dfs-dfs__graph--H50sL{display:flex;flex-direction:column;align-items:center}.components-dfs-dfs__container--2Nl4u{margin-bottom:5px}\n", ""]);
// Exports
exports.locals = {
	"node_discovered": "components-dfs-dfs__node_discovered--3Gxcd",
	"node_visited": "components-dfs-dfs__node_visited--JFfyg",
	"node_active": "components-dfs-dfs__node_active--1NKWV",
	"colorKey": "components-dfs-dfs__colorKey--2ajJs",
	"graph": "components-dfs-dfs__graph--H50sL",
	"container": "components-dfs-dfs__container--2Nl4u"
};
module.exports = exports;
